import { InvalidServiceName } from "./sofe";
export function getPublicPath(appName) {
  try {
    // systemjs will either throw if it can't find it, or occasionally return the same name you've given it, or hopefully actually return a url
    const appUrl = window.System.resolve(appName);
    if (appUrl !== appName) {
      return appUrl.slice(0, appUrl.lastIndexOf("/") + 1);
    } else {
      // SystemJS occasionally resolves with the same name that you give it, instead of a url. in that case, throw so we try again with !sofe
      throw Error("not found");
    }
  } catch (err1) {
    // if we didn't find it without !sofe, let's try to find it with !sofe
    try {
      const appSofeUrl = window.System.resolve(`${appName}!sofe`);
      if (appSofeUrl !== appName) {
        return appSofeUrl.slice(0, appSofeUrl.lastIndexOf("/") + 1);
      } else {
        throw Error("not found");
      }
    } catch (err2) {
      // single-spa-canopy expects this exact error type if we don't find the appName in SystemJS, so let's make sure to throw it in both cases if not found.
      // because we're using a custom error, the 'new' keyword is required
      throw new InvalidServiceName(`Cannot get service url for ${appName}`);
    }
  }
}
